import { TextField } from '@material-ui/core';
import React from 'react';
import {
    Button,
    ButtonToolbar,
    Drawer,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputNumber,
    InputPicker,
    Panel,
    Row,
    Loader
} from 'rsuite';
import { lettersNumbersDashesInputMask, limitCharacterLength, numbersInputMaskNullToZero, textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryContainerSkuDataElements from '../../../dataComponentsInventory/InventoryContainerSkuDataElements';
import Autocomplete from '@material-ui/lab/Autocomplete';



const tableIdCol = "SkuID"

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};




const dropDownLclFcl = [
    {
        "label": "LCL",
        "value": "LCL"
    },
    {
        "label": "FCL",
        "value": "FCL"
    }
]


// const treeRef = React.useRef();
// const [index, setIndex] = React.useState(1);

// function onClick={() => {
//     // https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#scrolltorow-index-number
//     treeRef.current.list.scrollToRow(index);
//   }}

const mountRef = React.createRef();

const AddToContainerDrawer = ({ onChangeAddToContainer, onClickTree, onClickEditSku, onChangeEditSku, onConfirmEditSku, onCancelEditSku, onClickDeleteSku, onChangeProject, onChangeUnits, onChangeCases, onChangePallets, onChangeNotes, onChangeCustomerRef, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showAddToContainerDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Build Container</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                {/* { state.selectedType === null ? <div></div> : */}

                {state.showSkuEdit ?


                    props.isInventoryPostLoading ?
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                                <Loader
                                    type="TailSpin"
                                    color="#3396E5"
                                    height="50"
                                    width="50"
                                />
                                <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait...</p>
                            </div>

                            :


                            <Panel bordered style={{ marginBottom: 10 }} >
                                <div>
                                    <h5 style={{ marginBottom: 5 }}>
                                        Edit SKU: {state.skuEditSKU}
                                    </h5>

                                    <FormGroup>


                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Expected Units:</InputGroup.Addon>
                                            {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                            <InputNumber value={state.skuEditUnits} className={'custom-input-number'} onChange={(e) => onChangeEditSku(e, 'skuEditUnits')} scrollable={false} />
                                        </InputGroup>
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Expected Cases:</InputGroup.Addon>
                                            {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                            <InputNumber value={state.skuEditCases} className={'custom-input-number'} onChange={(e) => onChangeEditSku(e, 'skuEditCases')} scrollable={false} />
                                        </InputGroup>
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Expected Pallets:</InputGroup.Addon>
                                            <InputNumber value={state.skuEditPallets} className={'custom-input-number'} onChange={(e) => onChangeEditSku(e, 'skuEditPallets')} scrollable={false} />
                                        </InputGroup>


                                    </FormGroup>

                                    <FormGroup>
                                        <ButtonToolbar>
                                            <DebouncedButton onClick={onConfirmEditSku} buttonText="Save" />
                                            <Button onClick={onCancelEditSku} appearance="default">Cancel</Button>
                                        </ButtonToolbar>
                                    </FormGroup>

                                </div>

                            </Panel>



                    :
                    <Panel bordered style={{ height: '420px', marginBottom: 10 }} >
                        <div>
                            <h5 style={{ marginBottom: 5 }}>
                                Add new SKU to {state.selectedLabel}
                            </h5>

                            <FormGroup>

                                {!props.isInventorySkuRefLoading && (
                                    <div style={{ marginBottom: '5px', width: '100%' }}>


                                        <Autocomplete
                                            freeSolo
                                            options={props.InventorySkuRefData}
                                            getOptionLabel={(option) => option.label}
                                            value={props.InventorySkuRefData.find(option => option.label === state.addNewText) || null}
                                            onChange={(event, newValue) => {
                                                // Extract the label value and pass it to onChangeAddToContainer
                                                const labelValue = typeof newValue === 'string' ? newValue : newValue?.label;
                                                onChangeAddToContainer(event, labelValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="SKU..."
                                                    onBlur={(e) => {
                                                        const typedValue = e.target.value;
                                                        if (typeof typedValue !== 'undefined' && typedValue !== null && typedValue !== '') {
                                                            onChangeAddToContainer(null, typedValue);
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.target.blur(); // Remove focus from the input
                                                        }
                                                    }}
                                                    onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true, // Remove the underline
                                                        style: {
                                                            padding: '6px 10px', // Adjust padding to reduce height
                                                            border: '1px solid #ccc',
                                                            borderRadius: '4px',
                                                            fontSize: '14px',
                                                            boxSizing: 'border-box',
                                                            height: '36px', // Set a fixed height to match rsuitejs
                                                        },
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            padding: 0, // Remove padding from the input element
                                                            height: '100%', // Ensure the input element takes the full height
                                                        },
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            style={{
                                                width: '100%',
                                            }}
                                        />

                                    </div>
                                )}


                                <div style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputPicker preventOverflow
                                        container={() => mountRef.current}
                                        block
                                        creatable
                                        placeholder={'Customer Ref...'}
                                        value={state.newSkuCustomerRef}
                                        onChange={(e, value, label) => { onChangeCustomerRef(e, value, label) }}
                                        data={props.InventoryCustomerRefData}
                                        cleanable={true}
                                    />
                                </div>

                                <div style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputPicker preventOverflow
                                        container={() => mountRef.current}
                                        block
                                        creatable
                                        placeholder={'Project...'}
                                        value={state.newSkuProject}
                                        onChage={(e, value, label) => { onChangeProject(e, value, label) }}
                                        data={props.InventoryProjectData}
                                        cleanable={true}
                                    />
                                </div>

                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>Expected Units:</InputGroup.Addon>
                                    {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                    <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeUnits(e)} scrollable={false} onInput={(e) => e.target.value = numbersInputMaskNullToZero(e) } />
                                </InputGroup>
                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>Expected Cases:</InputGroup.Addon>
                                    {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                    <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeCases(e)} scrollable={false} onInput={(e) => e.target.value = numbersInputMaskNullToZero(e) }/>
                                </InputGroup>
                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>Expected Pallets:</InputGroup.Addon>
                                    <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangePallets(e)} scrollable={false} onInput={(e) => e.target.value = numbersInputMaskNullToZero(e) }/>
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                    <InputGroup.Addon>Notes:</InputGroup.Addon>
                                    <Input onChange={(e) => { onChangeNotes(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 400)} />
                                </InputGroup>

                            </FormGroup>

                            <FormGroup>
                                <ButtonToolbar>
                                    <DebouncedButton onClick={confirm} buttonText="Add" />
                                    <Button onClick={close} appearance="default">Close</Button>
                                </ButtonToolbar>
                            </FormGroup>

                        </div>

                    </Panel>
                
                }




                <FormGroup>
                    <Panel bordered  >

                        <Row>
                            {RTableGeneral(
                                tableIdCol,     //   keyColumn,
                                props.InventoryContainerSkuData,     //   tableData,
                                InventoryContainerSkuDataElements,       //   DataElements,
                                props.isInventoryContainerSkuLoading,        //   loading,

                                props.isMobile,        //   isMobile,
                                props.history,     //   history,

                                null,     //   sortColumn,
                                null,       //   sortType,
                                null,      //   handleSortColumn,

                                null, // checkColEntry,
                                null,       //   checkedKeys,
                                null,       //   handleCheckAll,
                                null,       //   handleCheck,

                                false,      //   boolSelectButton,
                                null,       //   onClickSelect,
                                null,       //   selectButtonText,
                                null,       //   selectHighlightRowKey,

                                true,       //   link1bool,
                                'Edit',     //   link1text,
                                onClickEditSku,     //   link1onClick,

                                true,       //   link2bool,
                                'Delete',       //   link2text,
                                onClickDeleteSku  //   link2onClick

                            )}
                        </Row>


                    </Panel>
                </FormGroup>


            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer >

);



AddToContainerDrawer.defaultProps = {
    show: false
};

export default AddToContainerDrawer;


